import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { STATUS_HEALTH_DOWN, STATUS_HEALTH_RISK } from "@/constants/status-health"
import { THeaderWithHealthStatus } from "@/types/healthStatus.d"
import { IEventHealthDecorated, IHealthStatus } from "@/types/healthStatus"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

const isHealthStatus = (value: IHealthStatus): boolean => {
  return value && typeof value === "object" && "status" in value
}

export const getPriority = (row: IEventHealthDecorated): number => {
  const criteria: THeaderWithHealthStatus[] = ["source", "transcode", "publish", "cdn"]

  for (const criterion of criteria) {
    const criterionValue: IHealthStatus = row[criterion]

    if (isHealthStatus(criterionValue)) {
      if (criterionValue.status === STATUS_HEALTH_DOWN) return 1
      if (criterionValue.status === STATUS_HEALTH_RISK) return 2
    }
  }
  return 3
}

export const sortRows = (rows: IEventHealthDecorated[]): IEventHealthDecorated[] =>
  rows.sort((a: IEventHealthDecorated, b: IEventHealthDecorated) => {
    return getPriority(a) - getPriority(b)
  })

export const numberFormatter = (value: number) =>
  Intl.NumberFormat(navigator.language, { notation: "compact" }).format(value)

const truncatedToTwoDecimals = (num: number) => Math.trunc(num * 100) / 100

export const formatToPercentageOrNumber = (
  type: "sec" | "plays" | "%" | undefined,
  value: number | null | undefined,
): string => {
  if (value === undefined || value === null) return "-"
  switch (type) {
    case "plays":
      return numberFormatter(value)
    case "sec":
      return `${truncatedToTwoDecimals(value)} sec`
    case "%":
      return `${truncatedToTwoDecimals(value)}%`
    default:
      return `${value}`
  }
}

export const fileToBinary = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer
      const bytes = new Uint8Array(arrayBuffer)
      resolve(bytes)
    }

    reader.onerror = () => {
      reject(new Error("Error al leer el archivo"))
    }

    reader.readAsArrayBuffer(file)
  })
}

export const filesToBinary = async (files: Array<File>) => {
  const binaryData = []

  for (const file of files) {
    const bytes = await fileToBinary(file)
    binaryData.push(bytes)
  }

  return binaryData
}

/**
 * Get Mimetype
 * Description: converts an array of bytes to string when there's no header in file string
 */
export const getMimeType = (byteArray: Uint8Array) => {
  const header = byteArray.subarray(0, 8)
  const hex = Array.from(header)
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("")
    .toUpperCase()

  if (hex.slice(8) === "66747970") return "video/mp4"

  switch (hex) {
    case "89504E470D0A1A0A": // PNG
      return "image/png"
    case "FFD8FFE000104A46": // JPG (JPEG)
      return "image/jpeg"

    default:
      return "application/octet-stream"
  }
}
/**
 * Convert String base64 To Bytes
 * Description: converts a string whit a file base64 into a Bytes Array
 */
export const convertStringBaseSixtyFourToBytes = (base64String: string) => {
  const binaryString = atob(base64String)
  const len = binaryString.length
  const bytes = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

/**
 * Convert String base64 To Blob
 * Description: converts a string whit a file base64 into a Blob type
 */
export const convertStringBaseSixtyFourToBlob = (base64String: string): Blob => {
  const bytes = convertStringBaseSixtyFourToBytes(base64String)
  const mimeType = getMimeType(bytes)
  const blob = new Blob([bytes], { type: mimeType })

  return blob
}
